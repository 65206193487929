import styled from "styled-components";
import { withTooltip } from "../../hoc/withTooltip";
import { Flex } from "../../ui";
import { convertHexToRGBA } from "../../utils";
import {
  BLACK,
  DARKEST_GREY,
  GREY_OPAQUE,
  GREEN,
  GREEN_ALPHA,
  WHITE,
  DARK_GREEN_ALPHA,
  DARK_GREEN
} from "../../utils/theme";

const getBackgroundColor = (color) => {
  // Handling specific cases so text is more legible or matches designs more
  if (color === DARKEST_GREY) {
    return convertHexToRGBA(GREY_OPAQUE, 0.3);
  }

  if (color === GREEN) {
    return GREEN_ALPHA;
  }

  if (color === DARK_GREEN) {
    return DARK_GREEN_ALPHA;
  }

  return convertHexToRGBA(color, 0.18);
};

const backgroundColor = ({ toggled, color = GREEN }) => (!toggled ? getBackgroundColor(color) : color);
const boxShadow = ({ toggled }) => (toggled ? "0px 1px 4px rgba(0, 0, 0, 0.25)" : "none");
const fontColor = ({ toggled }) => (toggled ? WHITE : BLACK);
const cursorType = ({ disabled }) => (disabled ? "not-allowed" : "pointer");

const FlexWithTooltip = withTooltip(Flex, {
  arrow: true,
  theme: "cloverleaf",
  style: { width: "100%" },
});

const TraitToggleWrapper = styled(FlexWithTooltip)`
  width: 100%;
  padding: ${props => (props.center ? "6px 10px 6px 10px" : "8px 16px")};
  border-radius: 5px;
  justify-content: ${props => (props.center ? "center" : "space-between")};
  align-items: center;
  background-color: ${props => backgroundColor(props)};
  box-shadow: ${props => boxShadow(props)};
  color: ${props => fontColor(props)};
  font-size: 1.1rem;

  &:hover {
    cursor: ${props => cursorType(props)};
    color: ${WHITE};
    background-color: ${props => props.color || GREEN};
  }

  &:active {
    color: ${WHITE};
    background-color: ${props => props.color || GREEN};
  }
`;

export default TraitToggleWrapper;

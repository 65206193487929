import compose from "lodash/fp/compose";
import curry from "lodash/fp/curry";
import filter from "lodash/fp/filter";
import first from "lodash/fp/first";
import flatMap from "lodash/fp/flatMap";
import groupBy from "lodash/fp/groupBy";
import isEmpty from "lodash/fp/isEmpty";
import map from "lodash/fp/map";
import mapValues from "lodash/fp/mapValues";
import reject from "lodash/fp/reject";
import values from "lodash/fp/values";
import { roundToMultipleOf } from "../../utils";

export const strengthsChartWeights = {
  Executing: {
    x: -1,
    y: 1,
  },
  "Strategic Thinking": {
    x: 1,
    y: 1,
  },
  "Relationship Building": {
    x: 1,
    y: -1,
  },
  Influencing: {
    x: -1,
    y: -1,
  },
  Wisdom: {
    x: 0.75,
    y: -1,
  },
  Transcendence: {
    x: -0.75,
    y: 1,
  },
  Courage: {
    x: -1,
    y: 0.0,
  },
  Temperance: {
    x: -0.75,
    y: -1,
  },
  Humanity: {
    x: 1,
    y: 0.0,
  },
  Justice: {
    x: 0.75,
    y: 1,
  },
};

// The difference in weights between each ranked strength as a percentage
export const STRENGTHS_RANK_WEIGHT = 0.1;

const getCurrentStrengthWeight = index => (1 + STRENGTHS_RANK_WEIGHT * 2) - (index * STRENGTHS_RANK_WEIGHT);

export const strengthsCoordinateReducer = (accumulator, currentValue, currentIndex) => {
  const weight = getCurrentStrengthWeight(currentIndex);
  accumulator.x += ((weight * strengthsChartWeights[currentValue.secondaryNameKey].x) / 5);
  accumulator.y += ((weight * strengthsChartWeights[currentValue.secondaryNameKey].y) / 5);

  return accumulator;
};

/**
 * Groups user scores that are neighboring values by some amount of units in each direction
 *
 * @param {Number} units maximum distance between user coordinates where users are considered overlapping
 *
 * @example _.groupBy(keyXyWithOverlapThreshold(OVERLAP_THRESHOLD));
 */
export const keyXyWithOverlapThreshold = units => ({ coordinates: { x, y } }) => {
  if (units <= 0) {
    return `${x}, ${y}`;
  }

  return `${roundToMultipleOf(units, x)}, ${roundToMultipleOf(units, y)}`;
};

const pluckUser = map("user");
const groupByCoordinates = groupingThreshold => groupBy(keyXyWithOverlapThreshold(groupingThreshold));

const formatXYUsers = compose(
  map(items => ({
    ...first(items).coordinates, // we know items has values & all the items have the same coordinates because they're keyed by coordinate.
    users: pluckUser(items),
  })),
  values,
);

export const groupAndFormatUsers = (coordinates, groupingThreshold = 0) => compose(
  formatXYUsers,
  groupByCoordinates(groupingThreshold),
)(coordinates);

export const getCenterOffset = (center, currentPos) => ({
  xOffset: (currentPos.x - center.x),
  yOffset: (currentPos.y - center.y),
});

export const addCoordinatesFromScores = calculateCoordinates => map(user => ({
  user,
  coordinates: calculateCoordinates(user),
}));

export const staggerUsers = mapValues((traits) => {
  traits.forEach((coordinates, index) => {
    if (coordinates.users.length === 2) {
      traits.splice(index, 1);
      traits.push({
        x: coordinates.x,
        y: -0.6,
        users: [coordinates.users[0]],
      });
      traits.push({
        x: coordinates.x,
        y: 0.6,
        users: [coordinates.users[1]],
      });
    }
  });

  return traits;
});

export const staggerUsersForVerticalChart = mapValues(traits => flatMap((coordinates) => {
  // If the coordinates have a grouping of 2, pull them apart on the X-axis
  if (coordinates.users.length === 2) {
    return [
      {
        x: 2,
        y: coordinates.y,
        users: [coordinates.users[0]],
      },
      {
        x: 4,
        y: coordinates.y,
        users: [coordinates.users[1]],
      },
    ];
  }
  // If the coordinates have a grouping of 3, pull them apart on the X-axis
  if (coordinates.users.length === 3) {
    return [
      {
        x: 3,
        y: coordinates.y,
        users: [coordinates.users[0]],
      },
      {
        x: 1.5,
        y: coordinates.y,
        users: [coordinates.users[1]],
      },
      {
        x: 4.5,
        y: coordinates.y,
        users: [coordinates.users[2]],
      },
    ];
  }

  // If no grouping, or grouping is more than 3 users, leave it as is
  return coordinates;
}, traits));

export const formatSpectrumCoordinatesAndUsersForVerticalChart = curry((getTraitsForUser, users) => compose(
  staggerUsersForVerticalChart,
  mapValues(groupAndFormatUsers),
  groupBy(item => item.name),
  flatMap((user) => {
    const traits = getTraitsForUser(user);

    return traits.map(({ nameKey: name, value }) => ({
      user,
      name,
      coordinates: { x: 3, y: Math.round(value * 4) / 4 },
    }));
  }),
)(users));

export const formatSpectrumCoordinatesAndUsers = curry((getTraitsForUser, users) => compose(
  staggerUsers,
  mapValues(groupAndFormatUsers),
  groupBy(item => item.name?.toLowerCase()),
  flatMap((user) => {
    const traits = getTraitsForUser(user);

    return traits.map(({ nameKey: name, value }) => ({
      user,
      name,
      coordinates: { x: Math.round(value * 4) / 4, y: 0 },
    }));
  }),
)(users));

export const staggerPrimaryUserForVecticalChart = (coordinatesWithUsers, primaryUser = {}) =>
  mapValues(flatMap((coordinates) => {
    const primary = filter((user = {}) => user.id === primaryUser.id, coordinates.users);
    if (isEmpty(primary) || coordinates.users.length < 2) {
      return coordinates;
    }

    return [
      {
        x: 3.75,
        y: coordinates.y,
        users: reject(user => user.id === primaryUser.id, coordinates.users),
      },
      {
        x: 2.0,
        y: coordinates.y,
        users: primary,
      },
    ];
  }), coordinatesWithUsers);

import React, { useState } from "react";
import {
  bool,
  node,
  string
} from "prop-types";
import { track } from "../../../lib";
import { trackingDataProp } from "../../../lib/graphql/propTypes";
import withClickHereTooltip from "../../hoc/withClickHereTooltip";
import { EVENT } from "../../utils/constants";
import TitleToggle from "./TitleToggle";

function TitleToggleContainer({
  title,
  subtitle,
  description,
  asHTML = false,
  startExpanded = false,
  clickHereTooltip = false,
  testId,
  trackingData,
}) {
  const [isToggled, setIsToggled] = useState(startExpanded);

  const TitleToggleComponent = clickHereTooltip
    ? withClickHereTooltip(TitleToggle)
    : TitleToggle;

  const { name: eventName, props: properties } = EVENT.ContentViewed;
  const handleOnClick = () => {
    if (trackingData) {
      track(eventName, {
        ...trackingData,
        action: properties.action[!isToggled ? "open" : "close"],
        contentType: properties.contentType.titleTrait,
        element: title,
      });
    }

    setIsToggled(!isToggled);
  };

  return (
    <TitleToggleComponent
      testId={testId}
      title={title}
      subtitle={subtitle}
      description={description}
      toggled={isToggled}
      asHTML={asHTML}
      onClick={handleOnClick}
    />
  );
}

TitleToggleContainer.displayName = "TitleToggleContainer";

TitleToggleContainer.propTypes = {
  title: string.isRequired,
  description: node.isRequired,
  asHTML: bool,
  startExpanded: bool,
  clickHereTooltip: bool,
  testId: string,
  trackingData: trackingDataProp,
};

export default TitleToggleContainer;

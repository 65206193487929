import React from "react";
import {
  bool,
  func,
  node,
  object,
  string
} from "prop-types";
import {
  Flex,
  Heading,
  Icon,
} from "../../ui";
import { EXTRA_EXTRA_LARGE, GREEN } from "../../utils/theme";
import TitleToggleDescription from "./TitleToggleDescription";

const getTestId = testId => (!testId ? {} : { "data-testid": testId });

function TitleToggle({
  title,
  subtitle,
  description,
  toggled,
  onClick,
  asHTML = false,
  testId,
  style,
}) {
  return (
    <Flex w={1} column>
      <Flex {...getTestId(testId)} align={subtitle ? "start" : "center"} w={1} mb={1} style={{ cursor: "pointer", userSelect: "none", ...style }} onClick={onClick}>
        <Icon icon="chevron-right" color={GREEN} size="2x" fixedWidth rotation={toggled ? 90 : 0} />
        {!subtitle ? (
          <Heading
            noMargin
            condensed
            size={2}
            dark={!toggled}
            style={{ fontSize: EXTRA_EXTRA_LARGE }}
          >
            {title}
          </Heading>
        ) : (
          <Flex column>
            <Heading
              noMargin
              condensed
              size={2}
              dark={!toggled}
              style={{ fontSize: EXTRA_EXTRA_LARGE }}
            >
              {title}
              <div style={{ fontSize: 18, marginTop: "6px" }}>{subtitle}</div>
            </Heading>
          </Flex>
        )}
      </Flex>
      {toggled && (
        <Flex w={1} my={1}>
          <TitleToggleDescription description={description} asHTML={asHTML} />
        </Flex>
      )}
    </Flex>
  );
}

TitleToggle.displayName = "TitleToggle";

TitleToggle.propTypes = {
  title: string.isRequired,
  description: node.isRequired,
  asHTML: bool,
  onClick: func.isRequired,
  toggled: bool.isRequired,
  testId: string,
  style: object,
};

export default TitleToggle;

import React from "react";
import { gql } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import get from "lodash/fp/get";

const getId = get("currentUser.id");

export const currentUserIdQuery = gql`
  query currentUserIdQuery {
    currentUser {
      id
    }
  }
`;
const withCurrentUserId = Component => (function WithCurrentUserId(props) {
  return (
    <Query query={currentUserIdQuery} errorPolicy="all">
      {({ data }) => (
        <Component currentUserId={getId(data)} {...props} />
      )}
    </Query>
  );
});

export default withCurrentUserId;

/* eslint-disable react/no-danger */
import React from "react";
import {
  bool,
  node,
  string
} from "prop-types";
import { Box, Flex } from "../../ui";
import { GREEN } from "../../utils/theme";

function TitleToggleDescription({
  color = GREEN,
  description,
  asHTML = false,
}) {
  const isDescriptionString = typeof description === "string";

  return (
    <Flex style={{ borderLeft: isDescriptionString ? `3px solid ${color}` : "none" }}>
      <Box w={isDescriptionString ? 0.95 : 1} ml={isDescriptionString ? "10px" : undefined}>
        {asHTML ? <div dangerouslySetInnerHTML={{ __html: description }} /> : description}
      </Box>
    </Flex>
  );
}

TitleToggleDescription.displayName = "TitleToggleDescription";

TitleToggleDescription.propTypes = {
  description: node.isRequired,
  color: string,
  asHTML: bool,
};

export default TitleToggleDescription;

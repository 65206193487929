import Router from "next/router";
import * as Sentry from "@sentry/nextjs";
import isUndefined from "lodash/fp/isUndefined";
import omitBy from "lodash/fp/omitBy";
import { getSubdomain } from "./getSubdomain";

const omitUndefined = omitBy(isUndefined);

const checkStatus = async (response, redirectOnUnauthorized) => {
  if (response.ok) {
    return response.json();
  }

  if (response.status === 401 && redirectOnUnauthorized) {
    // Server-side auth should be caught before loading the page,
    // So getting an error here should always be client side.
    Router.replace("/signin");

    // TODO: display global unauthorized error in alert
  }

  const responseText = await response.text();
  let errorResponse;

  try {
    errorResponse = JSON.parse(responseText);
  }
  catch (err) {
    errorResponse = `Unable to parse response, recieved ${response.status}`;

    Sentry.addBreadcrumb({
      category: "fetch",
      message: errorResponse,
      level: "error",
    });
  }

  if (errorResponse?.error) {
    Sentry.captureException(errorResponse?.error, {
      extra: {
        message: "Fetch Error",
        status: response.status,
      },
    });

    throw new Error(errorResponse?.error);
  }

  const error = new Error(response.status);
  error.response = errorResponse;
  throw error;
};

const fetchWrap = (url, options = {}, redirectOnUnauthorized) => {
  const subdomain = getSubdomain();

  return fetch(url, {
    credentials: "include",
    ...options,
    headers: omitUndefined({
      "cloverleaf-subdomain": subdomain,
      ...options.headers,
    }),
  })
    .then(res => checkStatus(res, redirectOnUnauthorized));
};
export { fetchWrap as fetch };

export default fetchWrap;

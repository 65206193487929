import React from "react";
import {
  arrayOf,
  number,
  bool,
  string,
  shape
} from "prop-types";
import isEmpty from "lodash/fp/isEmpty";
import { Dot } from "recharts";
import { publicUserPropType, trackingDataProp } from "../../../lib/graphql/propTypes";
import { TextLabel } from "../../ui";
import AvatarWithUserStatsCard from "../../ui/AvatarWithUserStatsCard";
import CircleContainer from "../../ui/CircleContainer";
import { getCircleImgSize, getCircleImgSizePx } from "../../utils";
import { EVENT } from "../../utils/constants";
import {
  DEFAULT_FONT,
  WHITE,
  GREY_OPAQUE,
  GREEN
} from "../../utils/theme";

function AvatarShape({
  cx,
  cy,
  users,
  avatarSize = 2,
  border = false,
  assessment,
  tooltip,
  trackingData,
}) {
  const multiple = users.length > 1;
  const size = multiple ? avatarSize + 1 : avatarSize;
  const avatarImgSize = getCircleImgSize(size);
  const avatarImgSizePx = getCircleImgSizePx(size);

  const renderAvatar = () => {
    if (isEmpty(users)) return null;

    return (
      <AvatarWithUserStatsCard
        style={{ position: "fixed" }}
        user={users[0]}
        size={size}
        border={border}
        assessment={assessment}
        noAnimate
        tooltip={tooltip}
        trackingData={{
          ...trackingData,
          component: EVENT.ContentViewed.props.component.baseballCard,
          target: EVENT.ContentViewed.props.target.teammate,
          action: EVENT.ContentViewed.props.action.open,
          targetUserId: users[0]?.id,
        }}
      />
    );
  };

  const renderDuplicate = () => (
    <CircleContainer size={size} border={border} tooltip={tooltip} style={{ position: "fixed" }}>
      <TextLabel thin uppercase color={WHITE} font={DEFAULT_FONT}>
        {users.length}
      </TextLabel>
    </CircleContainer>
  );

  return (
    <switch>
      <foreignObject
        x={cx - (avatarImgSize / 2)}
        y={cy - (avatarImgSize / 2)}
        width={avatarImgSizePx}
        height={avatarImgSizePx}
        overflow="visible"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
      >
        {multiple
          ? renderDuplicate()
          : renderAvatar()
        }
      </foreignObject>
      <Dot
        cx={cx}
        cy={cy}
        r={size * 5}
        fill={GREY_OPAQUE}
        stroke={GREEN}
        strokeWidth={border ? 2 : 0}
        style={{ pointerEvents: "all" }}
      />
    </switch>
  );
}

AvatarShape.propTypes = {
  cx: number.isRequired,
  cy: number.isRequired,
  users: arrayOf(publicUserPropType).isRequired,
  avatarSize: number,
  border: bool,
  assessment: string,
  tooltip: shape({}),
  trackingData: trackingDataProp,
};

export default AvatarShape;

import React from "react";
import { string } from "prop-types";
import { Box, Text } from "../../ui";

function TraitToggleTooltip({ title }) {
  return (
    <Box maxWidth={350}>
      <Text noMargin style={{ wordBreak: "break-word" }}>
        {title}
      </Text>
    </Box>
  );
}

TraitToggleTooltip.displayName = "TraitToggleTooltip";

TraitToggleTooltip.propTypes = {
  title: string.isRequired,
};

export default TraitToggleTooltip;

import compose from "lodash/fp/compose";
import concat from "lodash/fp/concat";
import curry from "lodash/fp/curry";
import entries from "lodash/fp/entries";
import map from "lodash/fp/map";
import partition from "lodash/fp/partition";
import pick from "lodash/fp/pick";
import reduce from "lodash/fp/reduce";
import replace from "lodash/fp/replace";
import {
  compactKeysByEntries,
  pluckId,
  concatAll
} from "../../../../utils";
import { TOGGLE_CANDIDATE, TOGGLE_TEAM_MEMBER } from "../../../../utils/strings";
import { PURPLE, GREEN } from "../../../../utils/theme";

export const TEAM_VISIBILITY = "teamVisibility";

export const reduceToggleValues = curry((bool, userIds) =>
  reduce((acc, userId) => ({ ...acc, [`userVisibility-${userId}`]: bool }), {}, userIds));

export const compactVisibleUsers = compose(
  compactKeysByEntries,
  entries,
);

export const concatUsersAndCandidates = team => concat(
  team?.candidates?.map(user => ({ ...user, isCandidate: true })),
  team?.users,
);

/**
 * Combines team members, candidates and paywalled members not shown (then orders by candidates, name)
 * @param {Object} team
 */
export const combineUserTypesFromTeam = team => concatAll(
  team.paywall.hiddenMembers.map(user => ({ ...user, isHidden: true })),
  team.candidates.map(user => ({ ...user, isCandidate: true })),
  team.users,
);

export const getToggleName = user => (user.isHidden ? `hiddenUser-${user.id}` : `userVisibility-${user.id}`);
export const getToggleColor = user => (user.isCandidate ? PURPLE : GREEN);
export const getToggleTooltip = user => (user.isHidden
  ? undefined
  : ({ title: user.isCandidate ? TOGGLE_CANDIDATE : TOGGLE_TEAM_MEMBER, position: "bottom" })
);

const removeFieldNamePrefix = replace("userVisibility-", "");

const pickVisibilityFields = map(pick(["id", "isCandidate"]));

const partitionByVisibility = curry((users, fieldEntries) => partition(({ id }) => (fieldEntries[id]), users));

const reduceFieldEntries = reduce((acc, [key, value]) => {
  const newKey = removeFieldNamePrefix(key);

  return { ...acc, [newKey]: value };
}, {});

const reduceVisibility = ([includedMembers, excludedMembers]) =>
  ({ includedMembers: pickVisibilityFields(includedMembers), excludedMembers: pickVisibilityFields(excludedMembers) });

export const buildTeamVisibility = curry((fields, users) => compose(
  reduceVisibility,
  partitionByVisibility(users),
  reduceFieldEntries,
  entries,
)(fields));

export const setAllUserToggles = curry((bool, users) => compose(
  reduceToggleValues(bool),
  pluckId,
)(users));

import { BASE_URL } from "../../../lib/constants";
import { fetch } from "../../utils/fetch";

export const fetchHostedPage = ({
  planId,
  addons,
  subscriptionId,
  quantity,
  organizationId,
}, opts = {}) => fetch(`${BASE_URL}/api/chargebee/checkout-url`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    planId,
    addons,
    subscriptionId,
    quantity,
    organizationId,
    ...opts,
  }),
});

export const fetchPortal = organizationId => fetch(`${BASE_URL}/api/chargebee/portal-url`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify({ organizationId }),
});

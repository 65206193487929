import React, { useContext } from "react";
import { useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import { noop } from "lodash/fp";
import {
  Box,
  Button,
  Drop,
  Form,
  FormField,
  Grommet,
  Markdown,
  TextArea,
} from "grommet";
import { Icon } from "@/cloverleaf-ui/components";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { EmployeeToolsContext } from "../EmployeeToolbox";
import { useToastMessage } from "@/lib/hooks";

const UPDATE_CONTENT_MESSAGE = gql`
  mutation updateContentMessage($id: ID!, $message: String!, $locale: String!) {
    updateContentMessage(id: $id, message: $message, locale: $locale) {
      id
      message
    }
  }
`;

export const useContentManager = () => {
  const { isEditable } = useContext(EmployeeToolsContext);

  const wrapContent = (trait = {}, { color, border, noMarkdown } = {}) => {
    if (isEditable && trait.contentDebug) {
      return (
        <ContentManager
          key={trait.contentDebug.id}
          contentDebug={trait.contentDebug}
          addWrappedBorder={border}
          color={color}
          noMarkdown={noMarkdown}
        >{trait.description || trait.message}</ContentManager>
      );
    }

    return trait.description || trait.message || "";
  };

  return {
    wrapContent,
  };
};

export function ContentManagerForm({
  contentDebug,
  onClose,
  updateChildren = noop,
}) {
  const [value, setValue] = React.useState(contentDebug.message || "");
  const { pushToast } = useToastMessage();

  const [updateContentMessage] = useMutation(UPDATE_CONTENT_MESSAGE);

  const handleSubmit = () => {
    updateContentMessage({
      variables: {
        id: contentDebug.id,
        message: value,
        locale: contentDebug.locale,
      },
    })
      .then((response) => {
        updateChildren(response.data.updateContentMessage.message, true);
        pushToast("Content successfully updated, refresh to see changes");
        onClose();
      })
      .catch(() => {
        pushToast("Content failed to update updated", "critical");
      });
  };

  // Create a Grommet form with a text box
  return (
    <Grommet theme={themeStyle}>
      <Box align="center" pad="large">
        <Form onSubmit={handleSubmit}>
          <FormField label="Edit the content">
            <TextArea
              placeholder="Content goes here..."
              value={value}
              onChange={(event) => {
                setValue(event.target.value)
                updateChildren(event.target.value)
              }}
              style={{ height: "200px", minWidth: "400px" }}
            />
          </FormField>
          <Button
            type="submit"
            label="Save"
            primary
          />
        </Form>
      </Box>
    </Grommet>
  );
}

function ContentManager({
  contentDebug,
  addWrappedBorder = false,
  color,
  children: defaultChildren,
  noMarkdown = false,
}) {
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [children, setChildren] = React.useState(defaultChildren);
  const [persistedChildren, setPersistedChildren] = React.useState(defaultChildren);
  const targetRef = useRef();

  const onClose = () => {
    setChildren(persistedChildren);
    setShowEditModal(false)
  };

  if (!contentDebug) {
    return children;
  }

  let addWrap = {};
  if (addWrappedBorder) {
    addWrap = {
      border: { color },
      margin: { top: "xsmall" },
      pad: "xsmall",
      round: "xsmall",
    };
  }

  return (
    <React.Fragment>
      <Box
        ref={targetRef}
        css={{ position: "relative" }}
        {...addWrap}
      >
        <Box css={{ position: "absolute", right: 0, background: "#fff" }} pad="xxsmall">
          <Button onClick={() => setShowEditModal(true)}>
            <Icon icon="edit" />
          </Button>
        </Box>
        {!noMarkdown && (
          <Markdown
            components={{
              p: {
                props: {
                  size: "small",
                }
              },
            }}
          >
            {children}
          </Markdown>
        )}{noMarkdown && children}
      </Box>
      {showEditModal && (
        <Drop
          align={{ bottom: "top" }}
          margin={{ top: "small", bottom: "small" }}
          target={targetRef.current}
          onEsc={onClose}
          onClickOutside={onClose}
        >
          <ContentManagerForm
            contentDebug={contentDebug}
            onClose={onClose}
            updateChildren={(message, isSaved) => {
              setChildren(message);
              if (isSaved) {
                setPersistedChildren(message);
                setShowEditModal(false);
              }
            }}
          />
        </Drop>
      )}
    </React.Fragment>
  );
}

export default ContentManager;

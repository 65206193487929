import { connect, useDispatch } from "react-redux";
import { addToast } from "../../modules/toasts";

const withToast = Component => connect(null, { addToast })(Component);

/**
 * @deprecated - use useToast hook instead
 */
export default withToast;

export const useToast = () => {
  const dispatch = useDispatch();

  const handleAddToast = (content) => dispatch(addToast(content));

  return handleAddToast;
};

import React from "react";
import { bool, string } from "prop-types";
import startsWith from "lodash/fp/startsWith";
import styled from "styled-components";
import { Icon } from "../../ui";
import { iconProp } from "../../utils/propTypes";
import { GREEN, WHITE } from "../../utils/theme";
import TraitToggleWrapper from "./TraitToggleWrapper";

const HoverIcon = styled(Icon)`
  user-select: none;

  ${TraitToggleWrapper}:hover & {
    color: ${WHITE};
  }
`;

// At this time, image-as-an-icon only supports black and white
const HoverIconImage = styled.img`
  width: 20px;
  height: 20px;
  filter: ${props => (props.toggled ? "none" : "invert()")};

  ${TraitToggleWrapper}:hover & {
    filter: none;
  }
`;

function ToggleTraitIcon({ toggled, icon, color = GREEN }) {
  if (startsWith("http", icon)) {
    return (
      <HoverIconImage src={icon} toggled={toggled} alt="Trait Icon" />
    );
  }

  return <HoverIcon icon={icon} color={toggled ? WHITE : color} fixedWidth />;
}

ToggleTraitIcon.displayName = "ToggleTraitIcon";

ToggleTraitIcon.propTypes = {
  color: string,
  toggled: bool.isRequired,
  icon: iconProp,
};

export default ToggleTraitIcon;

import get from "lodash/fp/get";
import orderBy from "lodash/fp/orderBy";
import {
  getValue,
  remap,
  isTTIUser,
  isTTIOrganizationOwned
} from "../../utils";
import {
  MEANS,
  GOAL,
  INTERNALLY_DRIVEN,
  LOOSE,
  STRICT,
  COMMUNITY,
  PROFESSIONAL,
  APPROACHABLE,
  GUARDED,
  SUPPORT,
  PERFORMANCE,
  EXTERNALLY_DRIVEN
} from "../../utils/strings";

export { isTTIUser, isTTIOrganizationOwned }; // re-exporting for convenience

export const spectrumMargin = (score, range, currentMin = 0, currentMax = 100) => {
  // Largest value is 0%, smallest value is 50%
  // Remap the range to -50-0, then take the absolute value to get the normalized spectrum
  if (range) {
    const getRangeValue = value => remap({
      value,
      currentMin,
      currentMax,
      newMin: -50,
      newMax: 0,
    });

    return `0 ${Math.abs(getRangeValue(range[1]))}% 0 ${Math.abs(getRangeValue(range[0]))}%`;
  }

  if (score > 0) {
    return `0 ${50 - (score * 50)}% 0 50%`;
  }

  return `0 50% 0 ${50 - Math.abs(score * 50)}%`;
};

export const spectrumBorderRadius = (score, range) => {
  if (range) {
    return "5px";
  }

  return score > 0 ? "0 5px 5px 0" : "5px 0 0 5px";
};

// TODO: this is used in a few places and ultimately should be sending t() values to LabeledSpectrum but isn't
export const personalitySpectrumValues = [
  {
    Introversion: -1,
    Extroversion: 1,
  },
  {
    Sensing: -1,
    iNtuition: 1,
  },
  {
    Thinking: -1,
    Feeling: 1,
  },
  {
    Judging: -1,
    Perceiving: 1,
  },
];

// TODO: this is used in a few places and ultimately should be sending t() values to TeamSpectrum but isn't
export const culturePulseSpectrumLabels = [
  {
    title: "Organizational Effectiveness",
    endLabels: [MEANS, GOAL],
  },
  {
    title: "Audience Orientation",
    endLabels: [INTERNALLY_DRIVEN, EXTERNALLY_DRIVEN],
  },
  {
    title: "Organizational Control",
    endLabels: [LOOSE, STRICT],
  },
  {
    title: "Group Identity",
    endLabels: [COMMUNITY, PROFESSIONAL],
  },
  {
    title: "Personal Approachability",
    endLabels: [APPROACHABLE, GUARDED],
  },
  {
    title: "Management Philosophy",
    endLabels: [SUPPORT, PERFORMANCE],
  },
];

export const getTraitMiddleValue = (max, min) => (max + min) / 2;

export const getSubtitle = (labels, traitValue, midValue) => {
  if (traitValue === midValue) {
    return undefined;
  }
  if (traitValue < midValue) {
    return labels.endLabels[0];
  }

  return labels.endLabels[1];
};

// Note, these are just used as a lookup and really should be a map, not an array.
export const motivatingValuesSpectrumLabels = (t) => ([
  {
    dbName: "theoretical",
    title: t("knowledge"),
    endLabels: [t("instinctive"), t("intellectual")],
  },
  {
    dbName: "economic",
    title: t("economic utility"),
    endLabels: [t("charitable"), t("resourceful")],
  },
  {
    dbName: "aesthetic",
    title: t("environment"),
    endLabels: [t("objective"), t("harmonious")],
  },
  {
    dbName: "social",
    title: t("social"),
    endLabels: [t("intentional"), t("altruistic")],
  },
  {
    dbName: "political",
    title: t("power"),
    endLabels: [t("collaborative"), t("commanding")],
  },
  {
    dbName: "regulatory",
    title: t("process"),
    endLabels: [t("innovative"), t("structured")],
  },
]);

export const orderByTraitId = orderBy("id", "asc");

export const CULTURE_PULSE_MAX_SCORE = 7;
export const CULTURE_PULSE_MIN_SCORE = 0;
export const PERSONALITY_MAX_SCORE = 100;
export const PERSONALITY_MIN_SCORE = 0;
export const MOTIVATING_VALUES_MAX_SCORE = 100;
export const MOTIVATING_VALUES_MIN_SCORE = 0;

export const normalizeScore = (value, max, min = 0) => (value - min) / (max - min);

/**
 * Take the score value, and the range max and mins and return the plottable value (decimal from 0-1)
 * at the appropriate location.
 * This is for a directional spectrum, where it starts at the middle and goes out from there. a -1 to 1 spectrum.
 * @param {*} value The score.
 * @param {*} max maximum spectrum value
 * @param {*} min minimum spectrum value
 */
export const normalizeScoreOnDirectionalSpectrum = (value, max, min = 0) => {
  const offset = (max + min) / 2; // if max = 10 and min = 1, then the middle is 5.5.

  return (value - offset) / (max - offset);
};

export const normalizeRangeOffsetOnSpectrum = (value, max, min = 0) => (value - min) / (max - min);

export const culturePulseSpectrumScore = score => normalizeScoreOnDirectionalSpectrum(
  score,
  CULTURE_PULSE_MAX_SCORE,
  CULTURE_PULSE_MIN_SCORE,
);

export const personalitySpectrumScore = (trait, index) => {
  const normalizedScore = normalizeScoreOnDirectionalSpectrum(
    trait.value,
    PERSONALITY_MAX_SCORE,
    PERSONALITY_MIN_SCORE,
  );
  const weight = personalitySpectrumValues[index][trait.secondaryNameKey];

  return (normalizedScore * weight);
};

export const motivatingValuesSpectrumScore = score => normalizeScoreOnDirectionalSpectrum(
  score,
  MOTIVATING_VALUES_MAX_SCORE,
  MOTIVATING_VALUES_MIN_SCORE,
);

export const discSpectrumValues = {
  D: {
    x: -1,
    y: 1,
  },
  I: {
    x: 1,
    y: 1,
  },
  S: {
    x: 1,
    y: -1,
  },
  C: {
    x: -1,
    y: -1,
  },
};

const getTeamUsers = get("users");
export const getValidUsers = (team, primaryUserOption, secondaryUserOption, filterUsersBy) => {
  const users = getTeamUsers(team);
  const primaryUserId = getValue(primaryUserOption);
  const secondaryUserId = getValue(secondaryUserOption);
  const filteredUsers = filterUsersBy(users);
  const primaryUser = filteredUsers.find(user => (primaryUserId === user.id));
  const secondaryUser = filteredUsers.find(user => (secondaryUserId === user.id));

  return {
    primaryUser,
    secondaryUser,
    filteredUsers,
  };
};

export const ComponentPreferenceView = {
  Me: 0,
  Profile: 1,
  Team: 2,
  CoachingView: 3,
};

export const ComponentLocation = {
  Me: "me-dashboard",
  Profile: "profile-page",
  MyAssessments: "my-assessments",
};

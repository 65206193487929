import React from "react";
import {
  string,
  bool,
  func,
  node
} from "prop-types";
import { track } from "../../../lib";
import { trackingDataProp } from "../../../lib/graphql/propTypes";
import { EVENT } from "../../utils/constants";
import { iconProp } from "../../utils/propTypes";
import TraitToggle from "../TraitToggle/TraitToggle";

class ToggleButtonComponent extends React.PureComponent {
  static propTypes = {
    onClick: func,
    title: node.isRequired,
    subtitle: node,
    toggled: bool,
    disabled: bool,
    color: string,
    icon: iconProp,
    center: bool,
    bold: bool,
    testId: string,
    trackingData: trackingDataProp,
  }

  static defaultProps = {
    onClick: undefined,
    subtitle: undefined,
    toggled: false,
    disabled: false,
    color: undefined,
    icon: undefined,
    center: false,
    bold: false,
    testId: undefined,
    trackingData: undefined,
  }

  render() {
    const {
      title, subtitle, onClick, toggled, disabled, color, icon, center, bold, testId, trackingData,
    } = this.props;

    const { name: eventName, props: properties } = EVENT.ContentViewed;
    const handleClick = () => {
      if (trackingData) {
        track(eventName, {
          ...trackingData,
          element: title,
          action: properties.action[!toggled ? "open" : "close"],
          contentType: properties.contentType.trait,
        });
      }
      onClick();
    };

    return (
      <TraitToggle
        title={title}
        toggled={toggled}
        color={color}
        icon={icon}
        subtitle={subtitle}
        onClick={handleClick}
        center={center}
        disabled={disabled}
        bold={bold}
        testId={testId}
      />
    );
  }
}

export default ToggleButtonComponent;

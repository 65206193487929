import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

export const updateMutation = gql`
  mutation updateChargebee($input: UpdateChargebeeInput!) {
    updateChargebee(input: $input) {
      success
    }
  }
`;

const refetchCheck = gql`
  query refetchChecks {
    check {
      canViewCreateOrganizationTeam
    }
  }
`;

const getUserIsPremiumQuery = gql`
  query getUserIsPremium {
    currentUser {
      id
      scores {
        strengthscope {
          isPremium
          graphic
          title {
            id
            name
            secondaryName
            description
          }
          traits {
            id
            name
            secondaryName
            secondaryNameKey
            iconUrl
            descriptions {
              label
              message
              isPremium
            }
          }
        }
      }
    }
  }
`;

export const withUpdateChargebee = graphql(updateMutation, {
  props: ({ mutate }) => ({
    updateChargebee: ({ hostedPageId } = {}) => mutate({
      variables: { input: { hostedPageId } },
      refetchQueries: [
        { query: refetchCheck },
        {
          query: getUserIsPremiumQuery,
          fetchPolicy: "network-only",
        },
      ],
      awaitRefetchQueries: true,
    }),
  }),
});

/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import {
  Markdown,
  Text,
  Box,
} from "grommet";
import { track } from "../../../lib";
import { trackingDataProp } from "../../../lib/graphql/propTypes";
import {
  Flex,
  Icon,
  TextButton,
} from "../../ui";
import AvatarWithUserStatsCard from "../../ui/AvatarWithUserStatsCard";
import { ASSESSMENT, EVENT } from "../../utils/constants";
import { GREY_OPAQUE } from "../../utils/theme";
import { useLocale } from "@/lib/hooks";

function ToggleButtonDetails({
  color,
  details,
  asHTML = false,
  onClick = () => undefined,
  trackingData,
}) {
  const { t } = useLocale();

  const { name: eventName, props: properties } = EVENT.ContentViewed;

  const handleClick = () => {
    if (trackingData) {
      track(eventName, {
        ...trackingData,
        action: properties.action.close,
        contentType: properties.contentType.trait,
      });
    }
    onClick();
  };

  if (typeof details === "string") {
    return (
      <Box
        border={{ color }}
        direction="row"
        margin={{ top: "xsmall" }}
        pad="xsmall"
        round="xsmall"
      >
        {asHTML ? (
          <div dangerouslySetInnerHTML={{ __html: details }} />
        ) : (
          <Markdown
            components={{
              p: {
                props: {
                  size: "small",
                }
              },
            }}
          >
            {details}
          </Markdown>
        )}
        <TextButton
          color={GREY_OPAQUE}
          hoverColor={color}
          onClick={handleClick}
          tooltip={{ title: t("close") }}
        >
          <Icon icon="times" />
        </TextButton>
      </Box>
    );
  }

  // TODO: This is currently strengthscope specific, make this more generic
  if (details?.showAvatars) {
    return (
      <React.Fragment>
        <Flex mt={1} wrap>
          {details?.users?.length ? details.users.map(user => (
            <Box key={user.id} mr={2} mb={1}>
              <AvatarWithUserStatsCard
                user={user}
                size={3}
                tooltip={{ theme: "light", title: user.fullName }}
                assessment={ASSESSMENT.STRENGTHSCOPE}
              />
            </Box>
          )) : (
            <Text>{t("dash.team-strengthscope.no-strengths")}</Text>
          )}
        </Flex>
        {details?.tabs}
      </React.Fragment>
    );
  }

  return details;
}

ToggleButtonDetails.propTypes = {
  color: PropTypes.string,
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  asHTML: PropTypes.bool, // Are the details an html string?
  users: PropTypes.array,
  trackingData: trackingDataProp,
};

export default ToggleButtonDetails;

import React from "react";
import { func } from "prop-types";
import * as Sentry from "@sentry/nextjs";
import { publicRuntimeConfig } from "../../../lib/config";
import { CHARGEBEE_SRC_URL } from "../../../lib/constants";
import { withUpdateChargebee } from "./updateMutation";
import { fetchHostedPage, fetchPortal } from "./utils";
const debug = require("debug")("cloverleaf:Chargebee");

const withChargebee = (Component, {
  waitUntilInitialized = false, // Should we wait to render the child component until chargebee is loaded
} = {}) => {
  class Chargebee extends React.PureComponent {
    static propTypes = {
      updateChargebee: func.isRequired,
    };

    // Flag used to prevent calling setState after unmount
    initialized = true;

    state = {
      chargebee: undefined,
    };

    componentDidMount() {
      // Dynamically add the chargebee script if needed
      if (window && !window.Chargebee) {
        const el = document.createElement("script");
        el.onload = () => {
          const chargebee = window.Chargebee.init({
            site: publicRuntimeConfig.CHARGEBEE_SITE,
          });
          if (this.initialized) this.setState({ chargebee });
        };
        el.setAttribute("src", CHARGEBEE_SRC_URL);
        document.body.appendChild(el);
      }
      else if (window && window.Chargebee) {
        if (this.initialized) this.setState({ chargebee: window.Chargebee.getInstance() });
      }
    }

    componentWillUnmount() {
      this.initialized = false;
    }

    handleCheckout = ({
      planId,
      addons, // addons should be an array of [{ id: String! }]
      organizationId,
      subscriptionId, // If a subscriptionId is passed, then this becomes a "checkout_existing" rather than "checkout_new"
      quantity, // optional quantity
      onCheckoutSuccess = () => undefined,
      onSuccess = () => undefined,
      onError = () => undefined,
      onClose = () => undefined,
    }) => {
      const { chargebee } = this.state;
      const { updateChargebee } = this.props;
      if (!planId && !addons) {
        Sentry.captureException("No planId or addons given to chargebee");
      }
      if (!chargebee) return null;
      chargebee.openCheckout({
        hostedPage: () => {
          const body = {
            planId,
            addons,
            subscriptionId,
            quantity,
            organizationId,
          };

          return fetchHostedPage(body, { embed: false })
            .catch((error) => {
              debug("Chargebee Fetch Hosted Page Error", error);
              Sentry.captureException(error, { extra: { message: "CHARGEBEE FETCH HOSTED PAGE ERROR" } });
              onError(new Error("An error occurred, our team has been notified."));
            });
        },
        success(hostedPageId) {
          debug("Checkout Success", hostedPageId);
          onCheckoutSuccess();
          updateChargebee({ hostedPageId })
            .then(() => onSuccess(hostedPageId))
            .catch((e) => {
              debug("updateChargebee Error", e);
            });
        },
        close: () => {
          debug("checkout new closed");

          return onClose();
        },
        step(step) {
          debug("checkout step:", step);
        },
        error(error) {
          debug("Chargebee Error", error);
          Sentry.captureException(error, { extra: { message: "CHARGEBEE ERROR" } });
          onError(error);
        },
      });

      return true;
    }

    handlePortal = ({
      organizationId,
      onError = () => undefined,
      onClose = () => undefined,
    }) => {
      const { chargebee } = this.state;

      if (!chargebee) return null;
      chargebee.setPortalSession(() => fetchPortal(organizationId));

      const portal = chargebee.createChargebeePortal();
      portal.open({
        close: () => {
          onClose();
          debug("checkout new closed");
        },
        error(error) {
          debug("Chargebee Error", error);
          Sentry.captureException(error, { extra: { message: "CHARGEBEE ERROR" } });
          onError(error);
        },
      });

      return true;
    }

    render() {
      if (waitUntilInitialized && !this.state.chargebee) return null;

      return <Component {...this.props} openCheckout={this.handleCheckout} openPortal={this.handlePortal} />;
    }
  }

  return withUpdateChargebee(Chargebee);
};

export default withChargebee;

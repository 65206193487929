import React from "react";
import { func } from "prop-types";
import {
  useMutation,
  useQuery,
  gql
} from "@apollo/client";
import get from "lodash/fp/get";
import { Box } from "../ui";
import { CLICK_HERE } from "../utils/strings";
import { withTooltip } from "./withTooltip";

export const getUserInteractedWithInsights = (loading, error, data) =>
  loading || !!error || !!get("currentUser.stateData.resultToggles.interacted", data);

const currentUserResultToggleStateQuery = gql`
  query coachTipsFeatureQuery {
    currentUser {
      id
      stateData {
        resultToggles {
          interacted
        }
      }
    }
  }
`;

const updateCoachingTipsState = gql`
  mutation updateCoachingTipsState($input: ResultTogglesStateInput!) {
    updateResultTogglesState(input: $input){
      id
      stateData {
        resultToggles {
          interacted
        }
      }
    }
  }
`;

const withClickHereTooltip = (Component) => {
  function ComponentWithClickHereTooltip({ onClick = () => undefined, ...props }) {
    const ComponentWithTooltip = withTooltip(Component);
    const [updateResultTogglesState] = useMutation(updateCoachingTipsState);

    const { loading, error, data } = useQuery(currentUserResultToggleStateQuery, { fetchPolicy: "cache-first" });

    const userInteractedWithInsights = getUserInteractedWithInsights(loading, error, data);

    const currentUserId = get("currentUser.id", data);

    const tooltip = !userInteractedWithInsights
      ? ({
        html: <Box w={100}>{CLICK_HERE}</Box>,
        position: "left",
        open: true,
      })
      : undefined;

    const handleClick = (...args) => {
      onClick(...args);
      if (!userInteractedWithInsights) {
        updateResultTogglesState({
          variables: {
            input: {
              interacted: true,
            },
          },
          optimisticResponse: {
            updateResultTogglesState: {
              __typename: "User",
              id: currentUserId,
              stateData: {
                __typename: "StateData",
                resultToggles: {
                  __typename: "ResultTogglesState",
                  interacted: true,
                },
              },
            },
          },
        });
      }
    };

    return (
      <ComponentWithTooltip
        {...props}
        tooltip={tooltip}
        onClick={handleClick}
      />
    );
  }

  ComponentWithClickHereTooltip.propTypes = {
    onClick: func,
  };

  return ComponentWithClickHereTooltip;
};

export default withClickHereTooltip;
